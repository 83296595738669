import React from 'react'
import LayoutContainer from 'components/templates/enterprise/t/Layout'
import { PremiumUser, PremiumUserArgs } from 'models/premium-user'
import AvatarImg from 'assets/images/enterprise/t-01/avatar_4.jpeg'
import EnterpriseImage from 'assets/images/enterprise/t-01/enterprise_rectangular_4.png'
import {
  BusinessProfileType,
  EnterpriseTemplates,
  EnterpriseLogoShape,
  SocialMedia,
  AvailableContentSections,
} from 'utils/constants'
import { toContentfulRaw } from 'utils/format'
import { EdgeType } from 'utils/edgeType'
import { ContentDisplayMode } from 'utils/contentDisplayMode'

const defaultData: PremiumUserArgs = {
  isDemo: true,
  id: '1',
  name: 'Arnold',
  firstLastName: 'Miranda',
  nameToDisplay: 'Arnold Miranda',
  avatarUrl: {
    file: {
      url: AvatarImg,
    },
  },
  businessProfileType: BusinessProfileType.Business,
  template: EnterpriseTemplates.MS_Normal,
  metadata: {
    pageTitle: 'PlayU | Enterprise T-01 | E4',
    theme: {
      behavior: {
        header: {
          edgeType: EdgeType.DiagonalFromTop,
        },
        sections: { displayMode: ContentDisplayMode.Swipe },
      },
      palette: {
        header: {
          backgroundColor: '#F03D25',
        },
        playuButton: {
          backgroundColor: '#F03D25',
        },
        backgroundColor: '#F8F8F8',
        textColor: '#798586',
        primary: {
          normal: '#F03D25',
          hover: '#F03D25',
        },
        secondary: {
          normal: '#F03D25',
          hover: '#F03D25',
          shadow: '0 0 0 0.12rem #2187DE',
        },
      },
    },
  },
  jobs: [
    {
      id: '1',
      title: 'Contador',
    },
  ],
  enterprise: {
    id: '1',
    name: 'Trumarketing',
    logoShape: EnterpriseLogoShape.Rectangle,
    logoImage: {
      file: {
        url: EnterpriseImage,
      },
    },
    playuButtonImage: {
      file: {
        url: EnterpriseImage,
      },
    },
  },
  contact: {
    id: '1',
    phoneNumbers: ['+51901422305'],
    email: 'hola@playu.pe',
    addresses: [
      {
        id: '1',
        description: {
          description: 'Lima, Peru',
        },
      },
    ],
    socialMedia: [
      {
        order: 1,
        id: '1',
        link: 'https://instagram.com/playu.shop',
        description: {
          description: 'Grey Group - Instragram',
        },
        type: SocialMedia.Instagram,
      },
      {
        order: 2,
        id: '2',
        link: '#',
        description: {
          description: 'Grey Group - LinkedIn',
        },
        type: SocialMedia.Linkedin,
      },
      {
        order: 3,
        id: '3',
        link: 'https://api.whatsapp.com/send?phone=51901422305&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20PlayU.',
        description: {
          description: 'Grey Group - Whatsapp',
        },
        type: SocialMedia.Whatsapp,
      },
      {
        order: 4,
        id: '4',
        link: 'https://www.facebook.com/playu.shop',
        description: {
          description: 'Grey Group - Facebook',
        },
        type: SocialMedia.Facebook,
      },
      {
        order: 5,
        id: '5',
        link: 'https://goo.gl/maps/85wDwnMdEymUcJP66',
        description: {
          description: 'Encuentranos aquí',
        },
        type: SocialMedia.GoogleMaps,
      },
      {
        order: 6,
        id: '6',
        link: 'https://www.youtube.com/channel/UCne87oRuqn9eEIZQ63A5EQg',
        description: {
          description: 'Visita nuestro Canal',
        },
        type: SocialMedia.Youtube,
      },
      {
        order: 7,
        id: '7',
        link: 'https://www.tiktok.com/@playu.shop',
        description: {
          description: 'Grey Group - TikTok',
        },
        type: SocialMedia.Tiktok,
      },
    ],
  },
  services: [
    {
      id: '1',
      icon: 'fa-solid fa-fire-flame-simple',
      title: 'Marketing y Publicidad',
      description: {
        raw: toContentfulRaw(
          'Grey Group ofrece producción de contenido a terceros que deseen mejorar su branch identity'
        ),
      },
    },
    {
      id: '2',
      icon: 'fa-solid fa-house-laptop',
      title: 'Producción Visual',
      description: {
        raw: toContentfulRaw(
          'Ofrecemos elaboración de diversas índoles visuales para diversos tipos de contenidos: digital, comercial, etc.'
        ),
      },
    },
  ],
}

const PageComponent: React.FC = () => (
  <LayoutContainer user={new PremiumUser(defaultData)} template={EnterpriseTemplates.MS_Normal} />
)

export default PageComponent
